import _splitString from "split-string";
import _extendShallow from "extend-shallow";
import _isPlainObject from "is-plain-object";
import _isExtendable from "is-extendable";
var exports = {};
var split = _splitString;
var extend = _extendShallow;
var isPlainObject = _isPlainObject;
var isObject = _isExtendable;

exports = function (obj, prop, val) {
  if (!isObject(obj)) {
    return obj;
  }

  if (Array.isArray(prop)) {
    prop = [].concat.apply([], prop).join(".");
  }

  if (typeof prop !== "string") {
    return obj;
  }

  var keys = split(prop, {
    sep: ".",
    brackets: true
  }).filter(isValidKey);
  var len = keys.length;
  var idx = -1;
  var current = obj;

  while (++idx < len) {
    var key = keys[idx];

    if (idx !== len - 1) {
      if (!isObject(current[key])) {
        current[key] = {};
      }

      current = current[key];
      continue;
    }

    if (isPlainObject(current[key]) && isPlainObject(val)) {
      current[key] = extend({}, current[key], val);
    } else {
      current[key] = val;
    }
  }

  return obj;
};

function isValidKey(key) {
  return key !== "__proto__" && key !== "constructor" && key !== "prototype";
}

export default exports;